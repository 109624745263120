<template lang="pug">
form.salvar-agendamentoCheckup(@submit.prevent='handleSubmit()')
        .waiting(v-show='waiting')
            ProgressSpinner(strokeWidth='4')
        div(v-show='! waiting')
            label.form-label(v-if="model.ie_tipo_agenda != 'E'") Tipo de checkup:
            Dropdown(
                :filter='true'
                v-model='model.cd_tipo_checkup'
                :options='options.tiposCheckup'
                optionLabel='ds_checkup'
                optionValue='id'
                dataKey='id'
                placeholder='- Selecione -'
            )

            div(v-if="options.estabelecimentos.length")
                DataTable.carrinho-datatable.mt-2(
                    :value='options.estabelecimentos'
                    dataKey='id'
                    :selection.sync="selectedEstabelecimento")

                    Column(selectionMode='single' bodyStyle='text-align: center;' headerStyle='width: 3em')
                    Column(headerStyle='width:40%' header='Estabelecimento' field='cd_estabelecimento.nm_fantasia')
                    Column(headerStyle='width:40%' header='Procedimentos')
                        template(#body='props')
                            p.cell-procedimento-checkup.my-1(v-for='procedimento, idx in props.data.cd_procedimentos' :key='idx' :class="{ 'strike': ! procedimento.id }") <b>{{ idx+1 }}.</b> {{ procedimento.ds_procedimento }}
                    Column(headerStyle='width:20%' bodyStyle='text-align:center' header='Valor')
                        template(#body='props')
                            span {{ $root.formatPrice(props.data.nr_preco) }}

            div(v-if='options.agendasConsulta.length')

                p.ta-center.mt-4 <b>Agendamento de consulta</b>
                label.form-label Especialista:

                Dropdown(
                    :filter='true'
                    v-model='model.cd_especialista'
                    :options='options.agendasConsulta'
                    optionLabel='nm_especialista'
                    optionValue='cd_especialista'
                    dataKey='cd_especialista'
                    placeholder='- Selecione -'
                )

                .mt-4
                    label.form-label Especialidade:
                    p.ta-center(v-if='! model.cd_especialista' style='font-size: 12px;') <b>Selecione especialista.</b>
                    Dropdown(v-else
                        :filter='true'
                        v-model='model.cd_especialidade'
                        :options="options.agendasConsulta.find(a => a.cd_especialista === this.model.cd_especialista).cd_especialidades"
                        optionLabel='nm_especialidade'
                        optionValue='cd_especialidade'
                        dataKey='cd_especialidade'
                        placeholder='- Selecione -'
                    )

                .mt-4

                    label.form-label Data:
                    .ta-center.mt-4(v-if='waitingDatas')
                        ProgressSpinner(strokeWidth='2')
                    p.ta-center(v-else-if='! options.datas.length' style='font-size: 12px;') <b>Sem data disponível.</b>
                    Calendar(
                        v-else
                        locale='pt-BR'
                        :first-day-of-week='-7'
                        :attributes="[ { highlight: true, dates: model.dt_agenda } ]"
                        :available-dates='options.datas'
                        @dayclick='onDayClick'
                    )

                .mt-4

                    label.form-label Horário:
                    ProgressBar(v-if='waitingHorarios' mode="indeterminate")
                    p.ta-center(v-else-if='! Object.keys($parent.options.horarios).length' style='font-size: 12px;') <b>Sem horário disponível.</b>
                    div(v-else)
                        div(v-for="(i_item, i_index) in $parent.options.horarios")
                            div(v-for="(j_item, j_index) in $parent.options.horarios[i_index]" style="margin-bottom:10px;border-bottom:1px solid #eee;padding-bottom:10px;")
                                p
                                    strong Turno: &nbsp;
                                    span {{j_item[0].hr_inicio}} às {{j_item[0].hr_fim}}
                                SelectButton.hr_horario(
                                    v-model='$parent.model.cd_horario_agendamento'
                                    :options='$parent.options.horarios[i_index][j_index]'
                                    optionLabel='text'
                                    optionValue='value'
                                    dataKey='value'
                                )
                                    template(#option='props')
                                        div(style='font-size: 0' @click='$parent.alertBloqueio(props); $parent.motivo_bloqueio = props.option.ds_motivo_bloqueio')
                                            div.status(:class="{ disabled: props.option.disabled, bloqueado: props.option.status == 'B' }")
                                            div.text {{ props.option.text }}

                                .ta-right
                                    Button.mt-2(
                                        style='font-size: 11px; max-width: 110px;'
                                        type='button'
                                        label='Criar horário' icon='jam jam-plus'
                                        @click='$parent.dialogCriarHorario_base=j_item[0], $parent.dialogCriarHorario_data = {}; $parent.dialogCriarHorario = true'
                                        )

                        div.text-caption
                            div.ll <div class='caption tl'></div> Livre
                            div.ll <div class='caption b0'></div> Bloqueado
                            div.ll <div class='caption to'></div> Ocupado

                div(v-if='$parent.model.cd_horario_agendamento')
                    .ta-center(v-if='$parent.$refs.buscarPaciente')
                        .box-submit.my-4(v-if='! $parent.waitingRemoverTodos\
                            && $parent.$refs.buscarPaciente.cd_pessoa_fisica\
                            && $parent.model.cd_horario_agendamento')
                            ProgressSpinner(v-if='waitingForm' strokeWidth='6')
                            Button(v-else label='Agendar' type="submit")

            div(v-else)
                .ta-center(v-if='$parent.$refs.buscarPaciente')
                    .box-submit.my-4(v-if='! $parent.waitingRemoverTodos\
                        && $parent.$refs.buscarPaciente.cd_pessoa_fisica\
                        && selectedEstabelecimento')
                        ProgressSpinner(v-if='waitingForm' strokeWidth='6')
                        Button(v-else label='Agendar' type="submit")

</template>

<style lang="scss">
    .salvar-agendamentoCheckup {
        .waiting {
            text-align: center;
            padding: 2em 0;
            .p-progress-spinner {
                width: 36px;
                height: auto;
            }
        }
    }
</style>

<script>
    import ProgressSpinner from 'primevue/progressspinner'
    import Dropdown from 'primevue/dropdown'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import SelectButton from 'primevue/selectbutton'
    import ProgressBar from 'primevue/progressbar'
	import Calendar from 'v-calendar/lib/components/calendar.umd'

    import { Agendamento } from '../../middleware'
    import moment from 'moment'
    export default {
        components: { ProgressSpinner, Dropdown, DataTable, Column, Button, Calendar, SelectButton, ProgressBar },
        created () {
            this.getTiposCheckup()
            this.$parent = this.$parent.$parent
        },
        watch: {
            'model.cd_tipo_checkup' (cd_tipo_checkup) {
                this.options.agendasConsulta = []
                this.selectedEstabelecimento = null
                if (cd_tipo_checkup) this.getTipoCheckupById(cd_tipo_checkup)
            },
            'model.cd_especialista' (cd_especialista) {
                if (cd_especialista) {
                    let agenda = this.options.agendasConsulta.find(a => a.cd_especialista === cd_especialista)
                    if (agenda.cd_especialidades.length === 1)
                        this.model.cd_especialidade = agenda.cd_especialidades[0].cd_especialidade
                }
            },
            'model.cd_especialidade' (cd_especialidade) {
                if (cd_especialidade) this.getDatas()
            },
            'model.dt_agenda' (dt_agenda) {
                if (dt_agenda) this.getHorarios()
            },
            'options.agendasConsulta' (arr) {
                if (! arr.length) {
                    this.model.cd_especialista = null
                    this.model.cd_especialidade = null
                    this.model.dt_agenda = null
                    this.$parent.model.cd_horario_agendamento = null
                    this.options.datas = []
                    this.$parent.options.horarios = []
                }
            }
        },
        data () {
            return {
                model: {
                    cd_tipo_checkup: null,
                    cd_especialista: null,
                    cd_especialidade: null,
                    dt_agenda: null,
                    cd_horario_agendamento: null
                },
                options: {
                    tiposCheckup: [],
                    estabelecimentos: [],
                    agendasConsulta: [],
                    datas: [],
                    horarios: []
                },
                selectedEstabelecimento: null,
                waiting: false,
                waitingForm: false,
                waitingDatas: false,
                waitingHorarios: false
            }
        },
        methods: {
			getTiposCheckup () {
				let configs = { ignorarEstabelecimento: true, usarCidade: true }
				this.waiting = true
				Agendamento.getTiposCheckup({}, configs).then(response => {
					this.waiting = false
					if (response.status === 200) {
						this.options.tiposCheckup = response.data
					}
				})
			},
			getTipoCheckupById (id) {
				this.estabelecimentos = []
				let configs = { ignorarEstabelecimento: true, usarCidade: true }
				this.waiting = true
				Agendamento.getTipoCheckupById(id, configs).then(response => {
					this.waiting = false
					if (response.status === 200) {
						this.options.estabelecimentos = []
						if (response.data.length) this.options.estabelecimentos = response.data
						else this.$toast.error('Tipo de check-up sem estabelecimentos habilitados', { duration: 3000 })
					}
				})
			},
			onDayClick (ev) {
				let dateChecked = false
				this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == ev.id) dateChecked = true })
				if (dateChecked) this.model.dt_agenda = moment(ev.id)._d
			},
            getDatas () {
				this.options.datas = []
				this.model.dt_agenda = null
				let params = {
					ie_tipo_agenda: 'C',
					ie_telemedicina: false,
					cd_estabelecimento: this.selectedEstabelecimento.cd_estabelecimento.id,
                    cd_especialista: this.model.cd_especialista,
                    cd_especialidade: this.model.cd_especialidade
				}
				this.waitingDatas = true
				Agendamento.getDatasCadastro(params).then(response => {
					this.waitingDatas = false
					if (response.status == 200) {
						this.options.datas = []
						response.data.forEach(data => {
							this.options.datas.push(moment(data.dt_agenda)._d)
						})
					}
				})
			},
            getHorarios () {
                this.$parent.options.horarios = []
				this.$parent.model.cd_horario_agendamento = null
				let params = {
					ie_tipo_agenda: 'C',
					ie_telemedicina: false,
					cd_estabelecimento: this.selectedEstabelecimento.cd_estabelecimento.id,
					dt_consulta: moment(this.model.dt_agenda).format('YYYY-MM-DD'),
                    cd_especialista: this.model.cd_especialista,
                    cd_especialidade: this.model.cd_especialidade
				}
				this.waitingHorarios = true
				Agendamento.getHorariosCadastro(params).then(response => {
                    this.waitingHorarios = false
                    if (response.status == 200) {
                        this.$parent.options.horarios = {}
                        response.data.forEach(horario => {
                            if (this.$parent.options.horarios[horario.cd_agenda] == undefined)
                                this.$parent.options.horarios[horario.cd_agenda] = {}

                            if (this.$parent.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento] == undefined)
                                this.$parent.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento] = []

                            horario.ie_status = horario.status
                            this.$parent.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento].push({
                                value: horario.id,
                                text: moment(horario.hr_agenda, 'HH:mm:ss', true).format('HH:mm'),
                                disabled: horario.ie_status != 'L',
                                status: horario.status,
                                cd_dia_atendimento: horario.cd_dia_atendimento,
                                hr_inicio: horario.hr_inicio,
                                hr_fim: horario.hr_fim,
                                cd_agenda: horario.cd_agenda,
                                nm_especialista: horario.nm_especialista,
                                ds_motivo_bloqueio: horario.cd_dia_bloqueio__ds_motivo_bloqueio
                            })
                        })

                        for (let i in this.$parent.options.horarios) {
                            for (let j in this.$parent.options.horarios[i]) {
                                this.$parent.options.horarios[i][j].sort( (a, b) => {
                                    return Date.parse('01/01/2011 '+a.text+':00') > Date.parse('01/01/2011 '+b.text+':00')
                                } )
                            }
                        }
                    }
                })
            },
            handleSubmit () {

                let dataSend = Object.assign({}, this.model)
                let agenda = this.options.agendasConsulta.find(a => a.cd_especialista === this.model.cd_especialista)

                let _returnSuccess = (responseData) => {
                    let agendamento = {
                        id: responseData.id,
                        paciente: this.$parent.$refs.buscarPaciente.model,
                        info: {},
                        dataSend
                    }
                    agendamento.info.nm_paciente = agendamento.paciente.nm_pessoa_fisica
                    agendamento.info.nm_estabelecimento = this.selectedEstabelecimento.cd_estabelecimento.nm_fantasia
                    agendamento.info.cd_estabelecimento = responseData.cd_estabelecimento
                    agendamento.info.nm_endereco_estabelecimento = responseData.nm_endereco_estabelecimento
					agendamento.info.nr_telefone_estabelecimento = responseData.nr_telefone_estabelecimento
                    agendamento.info.ie_tipo_agenda_f = 'Consulta (check-up)'
                    agendamento.info.ie_tipo_atendimento_f = 'Presencial'
                    agendamento.info.ds_procedimento = '[' + responseData?.cd_procedimento + '] ' + responseData.ds_procedimento
                    agendamento.info.preco = responseData.nr_valor
                    agendamento.info.preco_f = this.$root.formatPrice(responseData.nr_valor)
                    agendamento.info.ie_tipo_agenda = 'C'
                    agendamento.info.nm_especialidade = agenda.cd_especialidades[0].nm_especialidade
                    this.$parent.agendamentos.push(agendamento)

                    this.$toast.success('Horário reservado!', { duration: 3000 })
                    this.options.agendasConsulta = []
                    this.selectedEstabelecimento = null
                }

                if (this.model.cd_especialista) { // Agendar consulta

                    let agenda = this.options.agendasConsulta.find(a => a.cd_especialista === this.model.cd_especialista)
                    let dataSend = {
                        ie_reservar: 'R',
                        cd_especialidade: this.model.cd_especialidade,
                        cd_horario_agendamento: this.$parent.model.cd_horario_agendamento,
                        cd_pessoa_fisica: this.$parent.$refs.buscarPaciente.cd_pessoa_fisica,
                        cd_checkup: this.selectedEstabelecimento.id
                    }

                    if (this.$parent.cliente) dataSend.cd_atendimento = this.$parent.cliente.atendimento.id
                    this.waitingForm = true
                    if (isNaN(dataSend.cd_horario_agendamento)) {

                        let lastOriginalHorario = this.$parent.horarioSelecionado.parent

                        dataSend = {
                            cd_pessoa_fisica: dataSend.cd_pessoa_fisica,
                            hr_agenda: dataSend.cd_horario_agendamento,
                            dt_agenda: moment(this.model.dt_agenda).format('YYYY-MM-DD'),
                            cd_especialidade: dataSend.cd_especialidade,
                            cd_agenda: lastOriginalHorario.cd_agenda,
                            cd_dia_atendimento: lastOriginalHorario.cd_dia_atendimento,
                            cd_atendimento: this.$parent.cliente.atendimento.id,
                            cd_checkup: this.selectedEstabelecimento.id
                        }

                        if( ['E', 'P'].includes(this.model.ie_tipo_agenda) ){
                            dataSend['cd_procedimento'] = this.selectedEstabelecimento.id
                        }

                        Agendamento.encaixarHorario(dataSend).then(response => {
                            this.waitingForm = false
                            if (response.status == 200) _returnSuccess(response.data)
                            else if (response.status == 400) {
                                if (response.data.cd_horario_agendamento) {
                                    if (typeof response.data.cd_horario_agendamento == 'string')
                                        this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
                                    else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))

                                    this.getHorarios()
                                }
                            }
                        })

                    } else {
                        Agendamento.reservarHorario(dataSend).then(response => {
                            this.waitingForm = false
                            if (response.status == 200) {
                                let agendamento = {
                                    id: response.data.id,
                                    paciente: this.$parent.$refs.buscarPaciente.model,
                                    info: {},
                                    dataSend
                                }
                                agendamento.info.nm_paciente = agendamento.paciente.nm_pessoa_fisica
                                agendamento.info.nm_estabelecimento = this.selectedEstabelecimento.cd_estabelecimento.nm_fantasia
                                agendamento.info.cd_estabelecimento = response.cd_estabelecimento
                                agendamento.info.nm_endereco_estabelecimento = response.nm_endereco_estabelecimento
                                agendamento.info.nr_telefone_estabelecimento = response.nr_telefone_estabelecimento
                                agendamento.info.ie_tipo_agenda_f = 'Consulta (check-up)'
                                agendamento.info.ie_tipo_atendimento_f = 'Presencial'
                                agendamento.info.ds_procedimento = '[' + response.data.cd_procedimento + '] ' + response.data.ds_procedimento
                                agendamento.info.preco = response.data.nr_valor
                                agendamento.info.preco_f = this.$root.formatPrice(response.data.nr_valor)
                                agendamento.info.ie_tipo_agenda = 'C'
                                agendamento.info.nm_especialidade = agenda.cd_especialidades[0].nm_especialidade
                                this.$parent.agendamentos.push(agendamento)

                                this.$toast.success('Horário reservado!', { duration: 3000 })
                                this.options.agendasConsulta = []
                                this.selectedEstabelecimento = null
                            } else if (response.status == 400) {
                                if (response.data.cd_horario_agendamento) {
                                    if (typeof response.data.cd_horario_agendamento == 'string')
                                        this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
                                    else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                                }
                            }
                        })
                    }
                } else { // Agendar procedimentos

                    let dataSend = {
                        ie_reservar: 'R',
                        cd_procedimentos: this.selectedEstabelecimento.cd_procedimentos.map(p => p.id),
                        cd_pessoa_fisica: this.$parent.$refs.buscarPaciente.cd_pessoa_fisica,
                        cd_horario_agendamento: 0,
                        cd_checkup: this.selectedEstabelecimento.id
                    }

                    if (this.$parent.cliente) dataSend.cd_atendimento = this.$parent.cliente.atendimento.id
                    this.waitingForm = true
                    Agendamento.reservarHorarioCheckup(dataSend).then(response => {
                        this.waitingForm = false
                        if (response.status == 200) {

                            response.data.forEach(item => {
                                let agendamento = {
                                    id: item.id,
                                    paciente: this.$parent.$refs.buscarPaciente.model,
                                    info: {},
                                    dataSend
                                }
                                agendamento.info.nm_paciente = agendamento.paciente.nm_pessoa_fisica
                                agendamento.info.nm_estabelecimento = this.selectedEstabelecimento.cd_estabelecimento.nm_fantasia
                                agendamento.info.cd_estabelecimento = item.cd_estabelecimento
                                agendamento.info.nm_endereco_estabelecimento = item.nm_endereco_estabelecimento
                                agendamento.info.nr_telefone_estabelecimento = item.nr_telefone_estabelecimento
                                agendamento.info.ie_tipo_agenda = item.ie_tipo_agenda
                                agendamento.info.ie_tipo_agenda_f = 'Procedimento (check-up)'
                                agendamento.info.ie_tipo_atendimento_f = 'Presencial'
                                agendamento.info.ds_procedimento = '[' + item.cd_procedimento + '] ' + item.ds_procedimento
                                agendamento.info.preco = item.nr_valor
                                agendamento.info.preco_f = this.$root.formatPrice(item.nr_valor)
                                agendamento.info.nr_quantidade = item.nr_quantidade
                                agendamento.info.cd_paciente = {id: item.cd_paciente}
                                this.$parent.agendamentos.push(agendamento)
                            })

                            this.$toast.success('Horários reservados!', { duration: 3000 })

                            if (this.selectedEstabelecimento.cd_estabelecimento.cd_agendas) {
                                this.options.agendasConsulta = this.selectedEstabelecimento.cd_estabelecimento.cd_agendas
                                if (this.options.agendasConsulta.length === 1) {
                                    this.model.cd_especialista = this.options.agendasConsulta[0].cd_especialista
                                }
                            } else this.selectedEstabelecimento = null

                        } else if (response.status == 400) {
                            if (typeof response.data === 'object')
                                Object.values(response.data).forEach(item => item.forEach(msg => this.$toast.error(msg, { duration: 3000 })))
                        }
                    })

                }
            }
        }
    }
</script>
