<template lang="pug">
	.main-wrapper.agendamento-revenda-salvar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-2
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-10.ta-right
					h1.text-header.text-secondary MedClub /
						<router-link :to='{ name: "agendamento" }'> Agendamentos</router-link> /
						<b>Criar</b>

		Dialog.dialogPagar(header="Confirmar Pagamento" :modal='true' :visible.sync='dialogPagar')
			.ta-center
				p <b>Será descontado {{ formatPrice(agendamentos_sum()) }} ?</b>
				p Seu saldo atual: <b>{{ saldo }}</b>

			.ta-center.my-4
					ProgressSpinner.small-spinner(v-if='waitingPagar' strokeWidth='6')
					Button.p-button-success(v-else label='Confirmar' icon='jam jam-check' @click='finalizar()')

		Dialog.dialog-Criar-Horario(header='Criar horário' :visible.sync='dialogCriarHorario' :modal='true')
			.ta-center.my-4
				label.form-label Horário de Atendimento:
				InputMask(v-model='dialogCriarHorario_data.hr_agenda' mask='99:99')
			.ta-center.my-4
				Button.p-button(label='Criar' @click='criarHorario()')

		Dialog.dialogAtendimentos(header='Atendimentos em aberto' :visible.sync='dialogAtendimentos' :modal='true')
			div(v-if='dialogAtendimentos_data.length')
				div
					p(style='margin:4px 0') <b>Cliente:</b> {{ dialogAtendimentos_data[0].cd_pessoa_fisica.nm_pessoa_fisica }}
					p(style='margin:4px 0' v-if='dialogAtendimentos_data[0].cd_pessoa_fisica.nr_cpf') <b>CPF:</b> {{ $root.formatCPF(dialogAtendimentos_data[0].cd_pessoa_fisica.nr_cpf, true) }}
				DataTable.my-4(:value='dialogAtendimentos_data' :selection.sync="dialogAtendimentos_selected"
					selectionMode="single" dataKey="id" @row-select='onAtendimentoSelect')
					Column(headerStyle='width:20%' bodyStyle='text-align:center' header='N° atendimento')
						template(#body='props')
							span {{ $root.padStart(props.data.id, 8) }}
					Column(headerStyle='width:20%' bodyStyle='text-align:center' header='Origem')
						template(#body='props')
							span(v-if="props.data.cd_origem_atendimento") {{ props.data.cd_origem_atendimento.ds_origem }}
							span(v-else) {{ props.data.ds_origem }}
					Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Data' field='dt_realizado')
					Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Qtd. itens' field='horarioagendamento_set.length')
					Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Valor total' field='nr_valor_total_f')
					Column(headerStyle='width:15%' bodyStyle='text-align:center')
						template(#body='props')
							Button.p-button-rounded(icon='jam jam-write' @click='dialogAtendimentos_selected = props.data; onAtendimentoSelect()')
				.ta-center
					Button(label='Novo atendimento' style='font-size:12px' @click='dialogAtendimentos = false; selecionarCliente(true)')

		Dialog.dialogCriarOrigem(header='Cadastrar Origem' :visible.sync='dialogCriarOrigem' :modal='true')
			.p-fluid.my-4
				label.form-label Origem *:
				InputText(v-model='dialogCriarOrigem_data.ds_origem')
			.ta-center.my-4
				ProgressSpinner.waitingOrigem(v-if='waitingOrigem' strokeWidth='6')
				Button.p-button(v-else label='Cadastrar' @click='criarOrigem()')

		.__box-imprimir(v-show='agendamentos.length')
			.ta-center(style='width: 100%; margin: 0 auto;')
				img.img-logo(src='./../../assets/img/logo-medclub.png')
				h1.title ORÇAMENTO

			.p-grid(v-if="cliente")
				.p-col-8
					span <b>Cliente:</b> {{ cliente.nm_pessoa_fisica }}
				.p-col-4
					span <b>CPF:</b> {{ cliente.nr_cpf_f }}

			table
				tr
					td(width='50%') <b>Procedimento / Especialidade</b>
					td(width='30%') <b>Estabelecimento</b>
					td(width='20%') <b>Preço</b>
				tr(v-for='agendamento, idx in agendamentos' :key='idx')
					td(v-if="agendamento.info.ie_tipo_agenda === 'C'")
						span {{ agendamento.info.nm_especialidade.length ? agendamento.info.nm_especialidade : agendamento.info.ds_procedimento }}
					td(v-else)
						span {{ agendamento.info.ds_procedimento }}
					td {{ agendamento.info.nm_estabelecimento }}
					td {{ agendamento.info.preco_f }}
				tr
					td.ta-right(colspan=2) <b>Total: </b>
					td {{ formatPrice(agendamentos_sum()) }}
			p.ta-right(style="font-size: 10px; margin: 0;") <b>Impresso em {{ dataImpressao }}</b>

		div(v-if='concluido')
			.p-col-12(v-if='guias.length')
					fieldset
						legend.text-title Guias
						DataTable.datatableGuias(:value='guias')
							Column(headerStyle='width:7%' field='cd_guia_eletronica' header='Cód.')
							Column(headerStyle='width:20%' field='nm_paciente' header='Paciente')
								template(#body='props')
									span {{ props.data.nm_paciente }}
									span.d-block.o-hidden(style="font-size:10px") Usuário: {{ props.data.nm_usuario_atendimento }}
							Column(headerStyle='width:20%' field='ds_procedimento' header='Procedimento')
							Column(headerStyle='width:18%' field='nm_estabelecimento' header='Estabelecimento')
							Column(headerStyle='width:10%' field='ds_guia_situacao' header='Situação')
								template(#body='props')
									p.highlight(:class="{\
											em: props.data.ds_guia_situacao === 'EMITIDA',\
											ex: props.data.ds_guia_situacao === 'EXECUTADA',\
											ca: props.data.ds_guia_situacao === 'CANCELADA'\
										}") {{ props.data.ds_guia_situacao }}
							Column(headerStyle='width:10%' field='nr_valortotal' header='Valor')
								template(#body='props')
									span(:class="{ 'valor-negativo': props.data.valortotal < 0 }") {{ formatPrice(props.data.valortotal) }}
							Column(headerStyle='width:15%' header='Ações' headerClass='box-hide' bodyClass='box-hide')
								template(#body='props')
									.ta-center
										.ml-1(style='display:inline-block;vertical-align:middle')
											ProgressSpinner.waitingImprimirGuia(v-if='waitingImprimirGuia == props.data.cd_horario_agendamento_id' strokeWidth='6')
											Button.p-button-raised.p-button-rounded(v-else-if='props.data.cd_horario_agendamento_id'
												icon='jam jam-printer' @click='imprimirGuia(props.data.cd_horario_agendamento_id)')

					.box-imprimir-todas.ta-right.mt-2
						ProgressSpinner(v-if='waitingImprimirTodasGuias' strokeWidth='6')
						Button.btn-finalizar(
							v-else
							type='button'
							label='Imprimir todas'
							icon="jam jam-printer"
							@click='imprimirTodasGuias()'
							)

		div(v-else)
			form(@submit.prevent='handleSubmit()')
				Panel(:header="`Criar agendamentos`")

					form.p-grid.p-fluid.p-justify-center(v-show='! cliente && ! desativarCliente' @submit='$event.preventDefault()')
						.p-col-6
							BuscarPaciente(ref='buscarPaciente2' label='Cliente' :cancelarPopup='true' :modoEmpresa='true')
						//- .p-col-3
						//- 	label.form-label Origem:
						//- 	ProgressBar(v-if='waitingOrigem' mode='indeterminate')
						//- 	.p-inputgroup(v-else)
						//- 		Dropdown(
						//- 			v-model='atendimentoOrigem'
						//- 			optionLabel='ds_origem'
						//- 			optionValue='id'
						//- 			dataKey='id'
						//- 			placeholder='- Selecione -'
						//- 			:filter='true'
						//- 			:options='options.origens'
						//- 			)
								//- Button(icon="jam jam-plus" @click="dialogCriarOrigem=true" v-tooltip.top="'Cadastrar nova origem'")
							//AutoComplete(v-model="atendimentoOrigem" :dropdown="true" :suggestions="options.origens" @complete="getOrigens($event)" forceSelection)
						.p-col-2.ta-center(v-if='waitingNomeUsuario')
							ProgressSpinner.waitingNomeUsuario(strokeWidth='5')
						.p-col-2(v-else style='margin-top:21px')
							Button(label='Selecionar' type='button' icon='jam jam-check' @click='selecionarCliente()')

					.p-grid.p-fluid.p-justify-center(v-show='cliente || desativarCliente')
						.p-col-6

							.p-grid

								.p-col-12(v-if='cliente')

									.p-grid.p-fluid

										.p-col-9
											label.form-label Nome do cliente:
											InputText.nomeUsuario2(readonly :value='`${ cliente.nm_pessoa_fisica }`')

										.p-col-3
											label.form-label N° atendimento:
											InputText.nomeUsuario2(readonly :value='`${ $root.padStart(cliente.atendimento.id, 8) }`')

								.p-col-12
									BuscarPaciente(ref='buscarPaciente')

								.p-col-12
									label.form-label Tipo de agendamento:
									SelectButton.ie_tipo_agenda(
										v-model='model.ie_tipo_agenda'
										:options='options.ie_tipo_agenda'
										optionLabel='text'
										optionValue='value'
									)

								.p-col-12(v-if="! ['CH'].includes(this.model.ie_tipo_agenda)")
									label.form-label Tipo de atendimento:
									SelectButton.ie_tipo_atendimento(
										v-model='model.ie_tipo_atendimento'
										:options='options.ie_tipo_atendimento'
										optionLabel='text'
										optionValue='value'
									)

								.p-col-12(v-if="['E', 'P'].includes(this.model.ie_tipo_agenda)")
									label.form-label Procedimento:
									ProgressBar(v-if='waitingProcedimentos' mode="indeterminate")
									p.ta-center(v-else-if='! options.procedimentos.length' style='font-size: 12px;') <b>Sem procedimento disponível.</b>
									Dropdown(
										v-else
										ref='dropdownProcedimentos'
										:filter='true'
										v-model='model.cd_procedimento'
										:options='options.procedimentos'
										optionValue='value'
										optionLabel='text'
										dataKey='id'
										placeholder='- Selecione -'
									)
										template(#option='props')
											span <b>{{ props.option.textAlt }}</b><br>
											span(v-if="props.option.textAlt != '- Selecione -' && props.option.ds_sinonimia") {{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }} ({{ props.option.ds_sinonimia }})
											span(v-else-if="props.option.textAlt != '- Selecione -'") {{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }}

								.p-col-12(v-if="['C'].includes(this.model.ie_tipo_agenda)")
									label.form-label Especialidade:
									ProgressBar(v-if='waitingEspecialidades' mode="indeterminate")
									p.ta-center(v-else-if='! options.especialidades.length' style='font-size: 12px;') <b>Sem especialidade disponível.</b>
									Dropdown(
										v-else
										:filter='true'
										v-model='model.cd_especialidade'
										:options='options.especialidades'
										optionLabel='text'
										optionValue='value'
										dataKey='value'
										placeholder='- Selecione -'
									)
										template(#option='props')
											span {{ props.option.textAlt }}

								.p-col-12(v-if="! ['CH'].includes(this.model.ie_tipo_agenda)")
									label.form-label(v-if="model.ie_tipo_agenda != 'E'") Estabelecimento:
									ProgressBar(v-if='waitingEstabelecimentos' mode="indeterminate")
									p.ta-center(v-else-if='(!model.cd_especialidade && !model.cd_procedimento) || !options.estabelecimentos.length' style='font-size: 12px;') <b>Sem estabelecimento disponível.</b>

									DataTable.carrinho-datatable(v-else-if="['E', 'P'].includes(this.model.ie_tipo_agenda)" @row-select='model.cd_estabelecimento = $event.data.cd_estabelecimento'
										:value='options.estabelecimentos' dataKey='id' :selection.sync="selectedEstabelecimento")
										Column(selectionMode='single' bodyStyle='text-align: center;' headerStyle='width: 3em')
										Column(headerStyle='width:50%' header='Estabelecimento')
											template(#body='props')
												p.aux1
													i(v-if="props.data.possui_integracao == true" style="color:#db7900" class="jam jam-bookmark-plus-f") &nbsp;
													b {{ props.data.nm_estabelecimento }}
												p.aux1(v-if='props.data.ds_observacao') {{ props.data.ds_observacao }}
												p.aux1.aux2(style='white-space: pre-wrap' v-if='props.data.ds_informacoes') {{ props.data.ds_informacoes }}
										Column(field='nr_valor_f' bodyStyle='text-align:center' header='Valor')
										Column(bodyStyle='padding: 0' header='Hora marcada')
											template(#body='props')
												.cell(:class="{ off: ! props.data.ie_hora_marcada }") {{ props.data.ie_hora_marcada_f }}
										Column(bodyStyle='padding: 0' header='Restrito')
											template(#body='props')
												.cell(:class="{ off: ! props.data.ie_restrito }") {{ props.data.ie_restrito_f }}


									Dropdown(
										v-else
										:filter='true'
										v-model='model.cd_estabelecimento'
										:options='options.estabelecimentos'
										optionLabel='text'
										optionValue='value'
										dataKey='value'
										placeholder='- Selecione -'
									)

								.p-col-12(v-if="['C'].includes(this.model.ie_tipo_agenda)")
									label.form-label Especialista:
									ProgressBar(v-if='waitingEspecialistasEstabelecimento' mode="indeterminate")
									p.ta-center(v-else-if='!options.especialistasEstabelecimento.length' style='font-size: 12px;') <b>Sem especialista disponível.</b>
									Dropdown(
										v-else
										:filter='true'
										:showClear="true"
										v-model='model.cd_especialista_estabelecimento'
										:options='options.especialistasEstabelecimento'
										optionLabel='text'
										optionValue='value'
										dataKey='value'
										@input='getDatasEspecialista'
										placeholder='- Selecione (opcional) -'
									)

								.p-col-12(v-if="['C'].includes(this.model.ie_tipo_agenda) || (['E', 'P'].includes(this.model.ie_tipo_agenda) && selectedEstabelecimento.ie_hora_marcada)")
									label.form-label Data:
									.ta-center.mt-4(v-if='waitingDatas')
										ProgressSpinner(strokeWidth='2')
									p.ta-center(v-else-if='! options.datas.length' style='font-size: 12px;') <b>Sem data disponível.</b>
									div(v-else style="position:relative")
										Calendar(
											locale='pt-BR'
											:first-day-of-week='-7'
											:attributes="[ { highlight: true, dates: model.dt_agenda } ]"
											:available-dates='options.datas'
											@dayclick='onDayClick'
										)
										span(@click='model.dt_agenda=null;options.datas=[];getDatasEspecialista(model.cd_especialista_estabelecimento)' v-if="model.dt_agenda" style='text-align:center;display:block;width:100%;color:#657786;cursor:pointer;font-size:.88em') limpar data

								.p-col-12(v-if="['C'].includes(this.model.ie_tipo_agenda)")
									label.form-label(style='position:relative')
										span Especialista / Valor:
									ProgressBar(v-if='waitingEspecialistas' mode="indeterminate")
									p.ta-center(v-else-if='! options.especialistas.length' style='font-size: 12px;') <b>Selecione uma data.</b>
									DataTable.datatable-epecialistas(v-else :value='options.especialistas'
										dataKey='value' :selection.sync="selectedEspecialista" @row-select='model.cd_especialista = $event.data.value')
										Column(selectionMode='single' bodyStyle='text-align: center;' headerStyle='width: 3em')
										Column(headerStyle='width:85%' field='text' header='Especialista')
											template(#body='props')
												div
													span <b>{{ props.data.text }}</b><br/>
													span(style='white-space: pre-wrap') {{ props.data.ds_observacao_agenda }}

										Column(headerStyle='width:15%' bodyStyle='text-align:center' field='preco_f' header='Preço')

								.p-col-12(v-show="['C'].includes(this.model.ie_tipo_agenda) || (['E', 'P'].includes(this.model.ie_tipo_agenda) && selectedEstabelecimento.ie_hora_marcada)")
									label.form-label Horários Disponíveis:
									ProgressBar(v-if='waitingHorarios' mode="indeterminate")
									p.ta-center(v-else-if='! Object.keys(options.horarios).length' style='font-size: 12px;') <b>Sem horário disponível.</b>
									div(v-else)
										div(v-for="(i_item, i_index) in options.horarios")
											div(v-for="(j_item, j_index) in options.horarios[i_index]" style="margin-bottom:10px;border-bottom:1px solid #eee;padding-bottom:10px;")
												p
													strong Turno: &nbsp;
													span {{j_item[0].hr_inicio}} às {{j_item[0].hr_fim}}
												SelectButton.hr_horario(
													v-model='model.cd_horario_agendamento'
													:options='options.horarios[i_index][j_index]'
													optionLabel='text'
													optionValue='value'
													dataKey='value'
												)
													template(#option='props')
														div(style='font-size: 0' @click='alertBloqueio(props); motivo_bloqueio = props.option.ds_motivo_bloqueio')
															div.status(:class="{ disabled: props.option.disabled, bloqueado: props.option.status == 'B' }")
															div.text {{ props.option.text }}

										div.text-caption
											div.ll <div class='caption tl'></div> Livre
											div.ll <div class='caption b0'></div> Bloqueado
											div.ll <div class='caption to'></div> Ocupado

								.p-col-12.ta-center(v-if='$refs.buscarPaciente')
									.box-submit.my-4(v-if='! waitingRemoverTodos\
										&& $refs.buscarPaciente.cd_pessoa_fisica\
										&& (model.cd_horario_agendamento || selectedEstabelecimento.ie_hora_marcada == false)')
										ProgressSpinner(v-if='waitingForm' strokeWidth='6')
										Button(v-else label='Agendar' type="submit")

								.p-col-12(v-if="['CH'].includes(this.model.ie_tipo_agenda)")
									AgendamentoCheckup

						.p-col-6(v-show='agendamentos.length')
							Panel(header='Reservados')
								.waitingRemoverTodos(v-if='waitingRemoverTodos')
									ProgressSpinner(strokeWidth='2')
									p.ta-center(style='color: #888') <b>Removendo...</b>
								.waitingNovoAtendimento(v-else-if='waitingNovoAtendimento')
									ProgressSpinner(strokeWidth='2')
									p.ta-center(style='color: #888') <b>Atualizando...</b>
								div(v-else)
									.p-grid.p-fluid
										.p-col-6(v-for='agendamento in agendamentos' :key='agendamento.id')
											Card.card-agendamento
												template(slot="content")
													.remove-button(v-if='waitingRemover == 0'
														@click='removerAgendamento(agendamento)') <i class='jam jam-close'></i>
													h1.text-title {{ agendamento.info.ie_tipo_agenda_f }}
													.waitingRemover(v-if='waitingRemover == agendamento.id')
														ProgressSpinner(strokeWidth='2')
													div(v-else)
														p.text1 <b>Paciente: </b> {{ agendamento.info.nm_paciente }}
														p.text1 <b>Local: </b> {{ agendamento.info.nm_estabelecimento }}
														div(v-if="agendamento.info.ie_tipo_agenda === 'C'" style='margin-top: 3px')
															p.text1 <b>Especialidade: </b> {{ agendamento.info.nm_especialidade.length ? agendamento.info.nm_especialidade : agendamento.info.ds_procedimento }}
														div(v-else style='margin-top: 3px')
															p.text1 <b>Procedimento: </b> {{ agendamento.info.ds_procedimento }}
														p.text1(v-if='agendamento.info.preco_f')
															b Preço:
																span(style='color: #657786; font-size: 16px;') {{ agendamento.info.preco_f }}
														div(v-if="['E', 'P'].includes(agendamento.info.ie_tipo_agenda)")
															p.text1
																b Quantidade:
																span(style='color: #657786; font-size: 16px;')
																	//span {{ agendamento.info.nr_quantidade }}
																	InputNumber( style="width:35px;text-align:center;" @focusout='incrementarQuantidade(agendamento, agendamento.info.nr_quantidade)' @keyup.enter.native='$event.target.blur()' :disabled="waitingQuantidade[agendamento.id]" :useGrouping="false" v-model="agendamento.info.nr_quantidade" buttonLayout="horizontal" :step="1"
																		decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal")
																	span.quantidade
																		span.btn-add(v-if='!waitingQuantidade[agendamento.id]' @click='incrementarQuantidade(agendamento,"+1")') +
																		span.btn-rem(v-if='!waitingQuantidade[agendamento.id]' @click='incrementarQuantidade(agendamento,"-1")') -

									.p-grid.p-fluid.p-justify-center(style="border-bottom:1px solid #eee;")
										.p-col-12
											p.ta-center <b>Valor total: <span style='color: #657786; font-size: 16px;'>{{ formatPrice(agendamentos_sum()) }}</span></b>
										.p-col-4
											ProgressSpinner(v-if="waitingImprimirTermica" strokeWidth='2' style='max-width:30px;')
											Button(v-else type='button' icon='jam jam-printer' label="Imprimir" @click='imprimirReservados()')
										.p-col-4
											Button(type='button' icon='jam jam-trash' label="Remover todos" @click='removerTodos()')
									.p-grid.p-fluid.p-justify-end
										.p-col-5.mt-2
											Button(type='button' icon='jam jam-refresh' label="Novo atendimento" @click='novoAtendimento()')
										.p-col-2.mt-2
											label &nbsp;
										.p-col-5.mt-2
											Button.p-button-success(type='button' icon='jam jam-coin' label="Pagar" @click='dialogPagar = true')

</template>

<style lang="scss">
	.agendamento-revenda-salvar {
		.text-caption {
			font-size: 12px;
			.ll{
				display: inline-block;
				margin-right:20px;
			}
			.caption {
				display: inline-block;
				height: 10px;
				width: 10px;
				background-color: #888;
				margin-right: 5px;
				border-radius: 10px;
				&.sa { background-color: #718096; }
				&.tl { background-color: #38a169; }
				&.hv { background-color: #d69e2e; }
				&.to { background-color: #f7de08; }
				&.b0 { background-color: #e53e3e; }
				&.fe { background-color: #9b67d5; }
			}
		}
		.box-imprimir-todas {
			.p-progress-spinner {
				height: 34px;
				width: 34px;
			}
		}
		.nomeUsuario2 {
			color: #000;
			font-weight: 600;
			background-color: #ddd;
		}
		.dialogAtendimentos {
			width: 96%;
			max-width: 720px;
		}
		.waitingImprimirGuia {
			height: 24px;
			width: 24px;
			margin-left: 10px;
		}
		.carrinho-datatable {
			max-height: 500px;
			overflow-y: auto;
            .cell {
                padding: 16px 0;
                text-align: center;
                background-color: #e4f8e1;
                &.off  { background-color: #f8d4d4; }
			}
			.p-highlight .cell {
				background-color: #007ad9 !important;
			}
			.cell-procedimento-checkup {
				margin: 0;
				&.strike {
					text-decoration: line-through;
				}
			}
		}
		.datatable-epecialistas {
			max-height: 300px; overflow-y: auto;
			td { font-size: 13px; }
		}
		.aux1 {
			margin: 0;
		}
		.aux2 {
			font-size: 13px;
			background-color: #FFFFE0;
			font-weight: 700;
			padding: 2px 6px;
			border-radius: 3px;
			margin-top: 4px;
		}
		.waitingRemoverTodos, .waitingNovoAtendimento {
			text-align: center;
			.p-progress-spinner {
				margin: 40px 0;
				width: 60px;
				height: 60px;
			}
		}
		.waitingRemover {
			text-align: center;
			.p-progress-spinner {
				margin: 8px 0;
				width: 41px;
				height: 41px;
			}
		}
		.waitingOrigem,
		.waitingNomeUsuario {
			width: 25px;
			height: auto;
			margin: 16px 0;
		}
		.__box-imprimir {
			width: 100%;
			.img-logo {
				max-width: 160px;
				margin: 20px 0;
			}
			.title {
				font-weight: 700;
				font-size: 15px;
			}
			table {
				width: 100%;
				border-collapse: collapse;
				td {
					padding: 2px 4px;
					font-size: 13px;
					border: 1px solid #000;
				}
			}
			@media screen {
				display: none;
			}
		}
		.card-agendamento {
			position: relative;
			.remove-button {
				position: absolute;
				top: 10px; right: 10px;
				cursor: pointer;
				background-color: #aaa;
				color: #fff;
				font-size: 0;
				border-radius: 50%;
				i {
					font-size: 16px;;
				}
			}
			.remove-button {
				position: absolute;
				top: 10px; right: 10px;
				cursor: pointer;
				background-color: #aaa;
				color: #fff;
				font-size: 0;
				border-radius: 50%;
				i {
					font-size: 16px;;
				}
			}
			.quantidade{
				display:inline-block;
				margin-left:20px;
			}
			.btn-add, .btn-rem {
				display:inline-block;
				margin-left:5px;
				top: 10px; right: 10px;
				cursor: pointer;
				background-color: #aaa;
				color: #fff;
				width:22px;
				text-align:center;
				border-radius: 50%;
				i {
					font-size: 16px;
				}
			}
			.btn-add{
				background-color:#38a169;
			}
			.btn-rem{
				background-color:#e53e3e
			};
			.text-title, .text1 {
				margin: 0;
			}
			.text-title {
				font-size: 15px;
				margin-bottom: 10px;
			}
			.text1 {
				font-size: 13px;
				margin-left: 16px;
			}
		}
		.p-selectbutton {
			&.ie_tipo_agenda {
				.p-button { width: 25% !important; }
			}
			&.ie_tipo_atendimento {
				.p-button { width: 50% !important; }
			}
			&.hr_horario {
				.status {
					display: inline-block;
					vertical-align: middle;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin-right: 10px;
					background-color: #38a169;
					border: 1px solid #298051;
					&.bloqueado {
						background-color: #e53e3e !important;
						border: 1px solid #c73434 !important;
					}
					&.disabled {
						background-color: #f7de08;
						border: 1px solid #d3be00;
					}
				}
				.text {
					display: inline-block;
					vertical-align: middle;
					font-size: 12px;
					font-weight: 700;
				}
				.p-button {
					padding: 6px;
					width: 20% !important;
				}
			}
			.p-button {
				.p-button-text {
					font-size: 12px;
				}
			}
		}
		.vc-container {
			margin: 20px auto;
		}
		.p-progressbar {
			margin: 18px 0;
		}
		.box-submit {
			padding: 0;
			.p-progress-spinner {
				height: 27px;
			}
		}
		.dialogCriarOrigem { width: 400px; max-width: 96%; }
		.dialog-Criar-Horario {
			width: 95%; max-width: 400px;
			.p-inputmask {
				text-align: center;
				font-size: 16px;
			}
		}
        .small-spinner {
            width: 29px;
            height: auto;
        }
	}
</style>

<script>
	import AutoComplete from 'primevue/autocomplete';
	import Panel from 'primevue/panel'
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import Button from 'primevue/button'
	import Dropdown from 'primevue/dropdown'
	import SelectButton from 'primevue/selectbutton'
	import Calendar from 'v-calendar/lib/components/calendar.umd'
	import BuscarPaciente from './../Paciente/BuscarPaciente'
	import Imprimir from '../Agendamento/Imprimir'
	import Dialog from 'primevue/dialog'
	import InputMask from 'primevue/inputmask'
	import InputText from 'primevue/inputtext'
	import InputNumber from 'primevue/inputnumber';
	import Card from 'primevue/card'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import AgendamentoCheckup from '../Agendamento/Salvar-agendamentoCheckup'
	import Tooltip from "primevue/tooltip"

	import moment from 'moment'
    import {pCalendarLocale_ptbr, removeAcentos, toastError} from './../../utils'
	import { Agendamento, Empresa, Caixas } from '../../middleware'
	export default {
		components: { AutoComplete, Panel, ProgressBar, Button, SelectButton, Dropdown, InputMask, Column,
			Calendar, ProgressSpinner, BuscarPaciente, Imprimir, Dialog, Card, DataTable, InputText, InputNumber, AgendamentoCheckup },
		directives: { tooltip: Tooltip },
		beforeRouteLeave (to, from, next) {
			if (this.cliente) Agendamento.finalizarAtendimento(this.cliente.id).then(() => {})
			next()
		},
		watch: {
			'model.ie_tipo_agenda': function () {
				if (['E', 'P'].includes(this.model.ie_tipo_agenda)) this.getProcedimentos()
				else if (['C'].includes(this.model.ie_tipo_agenda)) this.getEspecialidades()
			},
			'model.ie_tipo_atendimento': function () {
				if (['E', 'P'].includes(this.model.ie_tipo_agenda)) this.getProcedimentos()
				else if (['C'].includes(this.model.ie_tipo_agenda)) this.getEspecialidades()
			},
			'model.cd_especialidade': function () {
				this.getEstabelecimentos()
			},
			'model.cd_estabelecimento': function () {
				this.model.cd_especialista = null
				this.model.cd_especialista_estabelecimento = null
				this.getDatas()
				if (['C'].includes(this.model.ie_tipo_agenda)) {
					this.getEspecialistasEstabelecimento()
				}
			},
			'model.cd_procedimento': function (val) {
				//if (! this.model.cd_estabelecimento) {
					if (!val) this.model.id_procedimento = null
					else {
						let find = this.options.procedimentos.find(procedimento => procedimento.value == val)
						this.model.id_procedimento = find.id
					}

					this.getEstabelecimentos()
				//}
			},
			'model.dt_agenda': function (val) {
				if (['E', 'P'].includes(this.model.ie_tipo_agenda)) this.getHorarios()
				else if (['C'].includes(this.model.ie_tipo_agenda)) {
					if(val)
						this.getEspecialistas()
					//if (! this.model.cd_especialista) this.getEspecialistas()
					//else this.getHorarios()
				}
			},
			'model.cd_especialista_estabelecimento': function (val) {
                return val;
			},
			'model.cd_especialista': function (val) {
				//if (! val) this.options.especialistas = []
				if (val)
					this.getHorarios()
				//this.getDatas()
			}
		},
		created() {
			this.getSaldo()
			this.getOrigens()
		},
		data () {
			return {
				model: {
					ie_reservar: 'R',
					ie_tipo_agenda: null,
					ie_tipo_atendimento: null,
					cd_estabelecimento: null,
					id_procedimento: null,
					cd_procedimento: null,
					cd_especialista: null,
					cd_especialista_estabelecimento: null,
					cd_especialidade: null,
					dt_agenda: null,
					cd_horario_agendamento: null,
					nr_quantidade:1
				},
				saldo: null,
				dialogPagar: false,
				guias: [],
				concluido: false,
				waitingImprimirTodasGuias: false,
				waitingImprimirGuia: 0,
				horarioSelecionado:{},
				options: {
					ie_tipo_agenda: [
						{ value: 'C', text: 'Consulta' },
						{ value: 'E', text: 'Exame' },
						{ value: 'P', text: 'Procedimento' },
						{ value: 'CH', text: 'Check-up' }
					],
					ie_tipo_atendimento: [
						{ value: 1, text: 'Presencial' },
						{ value: 2, text: 'Vídeo' }
					],
					tipos_checkup: [],
					estabelecimentos: [],
					procedimentos: [],
					especialistas: [],
					especialistasEstabelecimento: [],
					especialidades: [],
					datas: [],
					horarios: [],
					origens: [],
				},
				selectedEstabelecimento: {},
				selectedEspecialista: {},
				selectedEspecialistaDropdown: false,
				ptbr: pCalendarLocale_ptbr,
				waitingForm: false,
				waitingPagar: false,
				waitingEstabelecimentos: false,
				waitingProcedimentos: false,
				waitingEspecialidades: false,
				waitingEspecialistas: false,
				waitingEspecialistasEstabelecimento: false,
				waitingDatas: false,
				waitingHorarios: false,
				waitingRemover: 0,
				waitingRemoverTodos: false,
				waitingNovoAtendimento: false,
				waitingNomeUsuario: false,
				waitingImprimirTermica: false,
				waitingQuantidade: {},
				agendamentos: [],
				dialogAtendimentos: false,
				cd_transacao_financeira: 0,
				dialogAtendimentos_data: [],
				dialogAtendimentos_selected: null,
				dialogCriarHorario: false,
				dialogCriarHorario_data: {},
				dialogCriarHorario_base: {},
				dataImpressao: moment().format('DD/MM/YYYY - HH:mm:ss'),
				motivo_bloqueio:"",
				agendamentos_sum () {
					let sum = 0
					this.agendamentos.forEach(agendamento => sum += agendamento.info.preco)
					return sum
				},
				cliente: null,
				atendimentoOrigem: null,
				dialogCriarOrigem: false,
				dialogCriarOrigem_data: {},
				waitingOrigem: false,
				desativarCliente: false
			}
		},
		methods: {
			alertBloqueio(p){
				this.horarioSelecionado = p.option
				if (p.option.ds_motivo_bloqueio != '' && p.option.ds_motivo_bloqueio != undefined)
					this.$toast.error('Horario Bloqueado: '+p.option.ds_motivo_bloqueio, { duration: 5000 })
			},
			findHorario(cd_horario_agendamento){
				for(let i in this.options.horarios){
					for(let j in this.options.horarios[i]){
						for(let k in this.options.horarios[i][j]){
							if(this.options.horarios[i][j][k].value === cd_horario_agendamento)
							return this.options.horarios[i][j][k]
						}

					}
				}
				return undefined
			},
			getSaldo() {
				Empresa.getSaldo()
				.then(response => {
					if([200, 201].includes(response.status)) {
						this.saldo = this.formatPrice(response.data.saldo)
					}
				})
			},
			handleSubmit () {
				let dataSend = Object.assign({}, this.model)
				let deleteKeys = ['ie_tipo_atendimento', 'dt_agenda', 'cd_especialista', 'cd_estabelecimento', 'ie_tipo_agenda', 'cd_tipo_checkup']
				Object.keys(dataSend).forEach(key => { if (dataSend[key] == null || deleteKeys.includes(key)) delete dataSend[key] })
				if (this.$refs.buscarPaciente) dataSend.cd_pessoa_fisica = this.$refs.buscarPaciente.cd_pessoa_fisica
				if (this.cliente) dataSend.cd_atendimento = this.cliente.atendimento.id

				let _returnSuccess = (responseData) => {
					let agendamento = {
						id: responseData.id,
						paciente: this.$refs.buscarPaciente.model,
						info: Object.assign({}, this.model),
						dataSend
					}

					agendamento.info.id = responseData.id
					agendamento.info.nm_paciente = agendamento.paciente.nm_pessoa_fisica
					agendamento.info.cd_paciente = {id:responseData.cd_paciente}
					agendamento.info.ie_tipo_agenda = this.model.ie_tipo_agenda
					agendamento.info.cd_procedimento_estabelecimento = responseData.cd_procedimento_estabelecimento
					if (['E', 'P'].includes(this.model.ie_tipo_agenda)) {
						let estabelecimento = this.selectedEstabelecimento
						agendamento.info.nm_estabelecimento = estabelecimento.nm_estabelecimento
						agendamento.info.preco_f = this.$root.formatPrice(responseData.nr_valor*responseData.nr_quantidade)
						agendamento.info.preco = responseData.nr_valor*responseData.nr_quantidade
						agendamento.quantidade = responseData.nr_quantidade
					} else {
						let estabelecimento = this.options.estabelecimentos.find(elem => elem.value == agendamento.info.cd_estabelecimento)
						agendamento.info.nm_estabelecimento = estabelecimento.text
					}

					agendamento.info.ie_tipo_agenda_f =
						this.options.ie_tipo_agenda.find(elem => elem.value == agendamento.info.ie_tipo_agenda).text

					agendamento.info.ie_tipo_atendimento_f =
						agendamento.info.ie_tipo_atendimento ?
							this.options.ie_tipo_atendimento.find(elem => elem.value == agendamento.info.ie_tipo_atendimento).text
							: 'Presencial'

					// if (agendamento.info.cd_horario_agendamento) agendamento.info.hr_agenda_f =
					// 	this.options.horarios.find(elem => elem.value == agendamento.info.cd_horario_agendamento).text
					if ( agendamento.info.cd_horario_agendamento )
						agendamento.info.hr_agenda_f = this.findHorario(agendamento.info.cd_horario_agendamento).text

					if (agendamento.info.cd_procedimento) {
						let find = this.options.procedimentos.find(elem => elem.value == agendamento.info.cd_procedimento)
						if (find) {
							agendamento.info.ds_procedimento = find.text
							agendamento.info.ds_procedimento = agendamento.info.ds_procedimento.split('/').length > 1 ? agendamento.info.ds_procedimento.split('/')[0] :  agendamento.info.ds_procedimento
						} else if (responseData.ds_procedimento)
							agendamento.info.ds_procedimento = responseData.ds_procedimento
					}

					if (agendamento.info.cd_especialidade) {
						let find = this.options.especialidades.find(elem => elem.value == agendamento.info.cd_especialidade)
						agendamento.info.nm_especialidade = find.text
					}
					if (agendamento.info.cd_especialista) {
						let find = this.options.especialistas.find(elem => elem.value == agendamento.info.cd_especialista)
						agendamento.info.nm_especialista = find.text
						agendamento.info.preco_f = this.$root.formatPrice(responseData.nr_valor)
						agendamento.info.preco = responseData.nr_valor
					}

					if (agendamento.info.dt_agenda) agendamento.info.dt_agenda_f =
						moment(agendamento.info.dt_agenda).format('DD/MM/YYYY')

					this.$toast.success('Horário reservado!', { duration: 3000 })
					this.agendamentos.push(agendamento)
					this.model.cd_procedimento = null
					this.model.cd_especialidade = null
					this.model.cd_especialista = null
					this.model.cd_estabelecimento = null
					this.selectedEstabelecimento = {}
					if (this.$refs.dropdownProcedimentos) {
						this.$refs.dropdownProcedimentos.filterValue = ''
						this.$refs.dropdownProcedimentos.$el.click()
					}
				}

				if (['E', 'P'].includes(this.model.ie_tipo_agenda) && ! dataSend.cd_horario_agendamento) dataSend.cd_horario_agendamento = 0

				this.waitingForm = true
				if (isNaN(dataSend.cd_horario_agendamento)) {
					//let lastOriginalHorario = this.options.horarios[this.options.horarios.length - 2]
					let lastOriginalHorario = this.horarioSelecionado.parent

					dataSend = {
						cd_pessoa_fisica: dataSend.cd_pessoa_fisica,
						hr_agenda: dataSend.cd_horario_agendamento,
						dt_agenda: moment(this.model.dt_agenda).format('YYYY-MM-DD'),
						cd_especialidade: dataSend.cd_especialidade,
						cd_agenda: lastOriginalHorario.cd_agenda,
						cd_dia_atendimento: lastOriginalHorario.cd_dia_atendimento,
						//cd_atendimento: this.cliente.id
						cd_atendimento: this.cliente.atendimento.id
					}

					if( ['E', 'P'].includes(this.model.ie_tipo_agenda) ){
						dataSend['cd_procedimento'] = this.selectedEstabelecimento.id
					}

					Agendamento.encaixarHorario(dataSend).then(response => {
						this.waitingForm = false
						if (response.status == 200) _returnSuccess(response.data)
						else if (response.status == 400) {
							if (response.data.cd_horario_agendamento) {
								if (typeof response.data.cd_horario_agendamento == 'string')
									this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
								else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))

								this.getHorarios()
							}
						}
					})

				} else {
					if (['E', 'P'].includes(this.model.ie_tipo_agenda)) {
						dataSend['cd_procedimento'] = this.selectedEstabelecimento.id
						delete dataSend.id_procedimento
					}
					Agendamento.reservarHorario(dataSend).then(response => {
						this.waitingForm = false
						if (response.status == 200)

							_returnSuccess(response.data)
						else if (response.status == 400) {
							/*
							if (response.data.cd_horario_agendamento) {
								if (typeof response.data.cd_horario_agendamento == 'string')
									this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
								else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
							}
							*/
							if (response.data.non_field_errors)
								response.data.non_field_errors.forEach(errorMsg => {
									this.$toast.error(errorMsg, { duration: 3000 })
								})
							else Object.values(response.data).forEach(errorMsg => {
								if (typeof errorMsg == 'object')
									this.$toast.error(errorMsg[0], { duration: 3000 })
								else this.$toast.error(errorMsg, { duration: 3000 })
							})
						}
					})
				}
			},
			imprimirGuia (id) {
                this.waitingImprimirGuia = id
                Agendamento.imprimirGuia(id).then(response => {
                    this.waitingImprimirGuia = 0
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            var fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);
                            //saveAs(blob, `guia-atendimento-${ id }.pdf`)
                        }
                    }
                })
            },
			imprimirTodasGuias () {
				this.waitingImprimirTodasGuias = true;
                return Caixas.imprimirGuiasLote(this.cd_transacao_financeira).then(response => {
                    if (response.status == 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
							this.waitingImprimirTodasGuias = false;
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            var fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);
                            //saveAs(blob, `guia-atendimento-${ this.id }.pdf`)
                        }
                    }
                    return 0
                })
            },
			selecionarCliente (force) {

				if (! this.$refs.buscarPaciente2.cd_pessoa_fisica) {
					this.$toast.error('Selecione um cliente', { duration: 3000 })
					return 0
				}
				// if (this.atendimentoOrigem === null) {
				// 	this.$toast.error('Campo "Origem" é obrigatório', { duration: 3000 })
				// 	return 0
				// }

				this.waitingNomeUsuario = true
				Agendamento.getAtendimentos({ cd_pessoa_fisica: this.$refs.buscarPaciente2.cd_pessoa_fisica }).then(response => {
					this.waitingNomeUsuario = false

					let atendimentosAnteriores = []
					if ([200, 201, 204].includes(response.status)) atendimentosAnteriores = response.data
					if (! atendimentosAnteriores.length || force) {

						const atendimentoDataSend = {
							cd_pessoa_fisica: this.$refs.buscarPaciente2.cd_pessoa_fisica,
							cd_origem_atendimento: this.atendimentoOrigem
						}
						this.waitingNomeUsuario = true
						Agendamento.criarAtendimento(atendimentoDataSend).then(response => {
							this.waitingNomeUsuario = false

							if ([200, 201, 204].includes(response.status)) {
								this.cliente = this.$refs.buscarPaciente2.model
								this.cliente.cd_pessoa_fisica = this.$refs.buscarPaciente2.cd_pessoa_fisica
								this.cliente.atendimento = response.data
								this.$refs.buscarPaciente.filtro.selected.value = this.cliente.nr_cpf
								this.$refs.buscarPaciente.buscar()
							}
						})

					} else {

						this.$toast.info('Cliente possui atendimentos em aberto', { duration: 3000 })
						atendimentosAnteriores.forEach(atendimento => {
							let nr_valor_total = 0
							atendimento.horarioagendamento_set.forEach(ag => {
								ag.nr_valor_total_f =
									new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
										.format(ag.nr_valor_total)
								if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
								nr_valor_total += parseFloat(ag.nr_valor_total)
							})
							atendimento.dt_realizado = moment(atendimento.dt_atualizado).format('DD/MM/YYYY')
							atendimento.hr_realizado = moment(atendimento.dt_atualizado).format('HH:mm')
							atendimento.nr_valor_total_f = this.formatPrice(nr_valor_total)
						})
						this.dialogAtendimentos_data = atendimentosAnteriores
						this.dialogAtendimentos = true

					}
				})
			},
			onAtendimentoSelect () {
				this.dialogAtendimentos = false

				let agendamentos = []
				let tipo_agenda = { 'C': 'Consulta', 'E': 'Exame' }
				this.dialogAtendimentos_selected.horarioagendamento_set.forEach(ag => {
					ag.ie_tipo_agenda_f = tipo_agenda[ag.ie_tipo_agenda]
					ag.preco_f = ag.nr_valor_total_f
					ag.preco = parseFloat(ag.nr_valor_total)
					if( ag.cd_procedimentos.length > 0 )
						ag.cd_procedimento_estabelecimento = ag.cd_procedimentos[0].cd_procedimento_estabelecimento
						delete ag.cd_procedimentos
					agendamentos.push({
						id: ag.id,
						paciente: this.$refs.buscarPaciente.model,
						info: ag,
						dataSend: ag
					})
				})

				this.agendamentos = agendamentos
				this.cliente = this.$refs.buscarPaciente2.model
				this.cliente.cd_pessoa_fisica = this.$refs.buscarPaciente2.cd_pessoa_fisica
				this.cliente.atendimento = this.dialogAtendimentos_selected
				this.$refs.buscarPaciente.filtro.selected.by = this.cliente.nr_cpf == null ? 'nm_pessoa_fisica' : 'nr_cpf'
				this.$refs.buscarPaciente.filtro.selected.value = this.cliente.nr_cpf == null ? this.cliente.nm_pessoa_fisica : this.cliente.nr_cpf
				this.$refs.buscarPaciente.buscar()
			},
			imprimirReservados () {
				//window.print()
				this.waitingImprimirTermica = true
				Agendamento.imprimeAtendimento(this.cliente.atendimento.id).then(response => {
					this.waitingImprimirTermica = false
					if (response.status == 200) {
						if (response.data.detail) {
							this.$toast.error(response.data.detail, { duration: 3000 })
						} else {
							let blob = new Blob([response.data], { type: 'text/html; charset=utf-8' })
							var fd = new FormData();
							fd.append('file', blob);
							fetch('http://localhost:9990/imprimir', {
								method: 'POST',
								body: fd
							}).catch( () => window.print())
						}
					}
				})
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			novoAtendimento () {
				this.waitingNovoAtendimento = true
				Agendamento.finalizarAtendimento(this.cliente.id).then(() => {
					this.waitingNovoAtendimento = false
					this.$router.go(0)
				})
			},
			finalizar() {

				this.waitingPagar = true;
				let nm_transacao_financeira = 'Pagamento cliente'

				let dataSend = {
					cd_atendimento: this.cliente.atendimento.id,
					nm_transacao_financeira,
					nr_valor: this.agendamentos_sum(),
					movimento_caixa: [],
					horarios: []
				}

				this.agendamentos.forEach(elem => {
					if(elem.dataSend.cd_horario_agendamento) {
						dataSend.horarios.push({
							id: elem.dataSend.cd_horario_agendamento,
							ie_status: elem.dataSend.ie_reservar,
						})
					} else if (elem.dataSend.id) {
						dataSend.horarios.push({
							id: elem.dataSend.id,
							ie_status: elem.dataSend.ie_status,
						})
					} else {
						dataSend.horarios.push({
							id: elem.id,
							ie_status: elem.dataSend.ie_reservar,
						})
					}
				})

				Empresa.pagarServicos(dataSend).then(response => {
					if([200, 201].includes(response.status)) {
						this.cd_transacao_financeira = response.data.id;
						Caixas.findTransacaoRevenda(response.data.id).then(response => {
                            this.waitingPagar = false;
                            this.dialogPagar = false;
                            if (response.status === 200) {
                                response.data.guias.forEach(guia => this.guias.push(guia));
                                this.concluido = true;
							} else {
                                this.$router.go(-1);
                            }
						})
						this.$toast.success('Agendamento realizado com sucesso!', { duration: 3000 })
					} else {
                        toastError(this, response);
                        this.waitingPagar = false;
                        this.dialogPagar = false;
					}
				})
			},
			pagarAtendimento(){
				this.$router.push({path:"/caixa/listar-guias", query:{pg:1, id:this.cliente.atendimento.id}})
			},
			removerAgendamento (agendamento) {
				if (agendamento.dataSend) {
					this.waitingRemover = agendamento.id
					agendamento.dataSend.ie_reservar = 'L'
					agendamento.dataSend.cd_horario_agendamento = agendamento.id
					Agendamento.reservarHorario(agendamento.dataSend).then(response => {
						if (response.status == 200) {
							this.agendamentos = this.agendamentos.filter(agendamento => agendamento.id != this.waitingRemover)
							this.waitingRemover = 0
							this.$toast.success('Agendamento removido', { duration: 3000 })
						}
					})
				}
			},
			removerTodos () {
				this.waitingRemoverTodos = true
				let _remover = (idx) => {
					if (idx == this.agendamentos.length) {
						this.waitingRemoverTodos = false
						this.agendamentos = []
						this.$toast.success('Agendamentos removidos', { duration: 3000 })
						return 0
					}
					this.agendamentos[idx].dataSend.ie_reservar = 'L'
					this.agendamentos[idx].dataSend.cd_horario_agendamento = this.agendamentos[idx].id
					Agendamento.reservarHorario(this.agendamentos[idx].dataSend).then(() => {
						_remover(++idx)
					})
				}; _remover(0)
			},
			getProcedimentos () {
				this.options.procedimentos = []
				this.model.cd_procedimento = null
				this.model.cd_especialidade = null

				// @params: ie_tipo_agenda*, ie_telemedicina*, dt_consulta, cd_estabelecimento, cd_procedimento
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2
				}

				this.waitingProcedimentos = true
				Agendamento.getProcedimentosCadastro(params).then(response => {
					this.waitingProcedimentos = false
					if (response.status == 200) {
						this.options.procedimentos = []
						if (response.data.length) this.options.procedimentos.push({ value: null, text:'- Selecione -', textAlt: '- Selecione -' })


						response.data.forEach(procedimento => {
							procedimento.ds_procedimento_m = removeAcentos(procedimento.ds_procedimento)

							this.options.procedimentos.push({
								value: procedimento.id,
								text: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento } / ${ procedimento.nm_procedimento } / ${ procedimento.ds_sigla } / ${ procedimento.ds_sinonimia } / ${ procedimento.ds_procedimento_m }`,
								textAlt: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento }`,
								nm_procedimento: procedimento.nm_procedimento,
								ds_sinonimia: procedimento.ds_sinonimia,
								ds_sigla: procedimento.ds_sigla,
								id: procedimento.id
							})
						})
					}
					this.getEstabelecimentos()
				})
			},
			getEspecialidades () {
				this.options.especialidades = []
				this.model.cd_especialidade = null
				this.model.cd_procedimento = null

				// @params: ie_tipo_agenda*, ie_telemedicina*, dt_consulta, cd_estabelecimento, cd_especialista
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2
				}

				this.waitingEspecialidades = true
				Agendamento.getEspecialidadesCadastro(params).then(response => {
					this.waitingEspecialidades = false
					if (response.status == 200) {
						this.options.especialidades = []
						if (response.data.length) this.options.especialidades.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -'  })

						response.data.forEach(especialidade => {
							especialidade.nm_especialidade_m = removeAcentos(especialidade.nm_especialidade)
							this.options.especialidades.push({
								value: especialidade.cd_especialidade,
								text: `${ especialidade.nm_especialidade } / ${ especialidade.nm_especialidade_m }`,
								textAlt: especialidade.nm_especialidade
							})
						})
					}
					this.getEstabelecimentos()
				})
			},
			getEstabelecimentos () {
				this.options.estabelecimentos = []
				this.model.cd_estabelecimento = null
				this.selectedEstabelecimento = {}

				// @params: ie_tipo_agenda*, ie_telemedicina*, dt_consulta (YYYY-MM-DD), cd_especialidade, cd_procedimento
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']

				if (['E', 'P'].includes(this.model.ie_tipo_agenda)) fields.push('cd_procedimento')
				else if (['C'].includes(this.model.ie_tipo_agenda)) fields.push('cd_especialidade')

				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2
				}

				if (this.model.cd_especialidade) params.cd_especialidade = this.model.cd_especialidade
				if (this.model.cd_procedimento) params.cd_procedimento = this.model.cd_procedimento

				this.waitingEstabelecimentos = true
				if (['E', 'P'].includes(this.model.ie_tipo_agenda)) {
					let params2 = Object.assign({}, params)
					params2.cd_procedimento = this.model.id_procedimento
					Agendamento.getEstabelecimentosProcedimentos(params2).then(response => {
						this.waitingEstabelecimentos = false
						if (response.status == 200) {
							this.options.estabelecimentos = []
							response.data.forEach(item => {
								item.ie_hora_marcada_f = item.ie_hora_marcada ? 'Sim' : 'Não'
								item.ie_restrito_f = item.ie_restrito ? 'Sim' : 'Não'
								item.nr_valor_f = this.formatPrice(item.nr_valor)
							})
							this.options.estabelecimentos = response.data
						}
					})
				} else {
					Agendamento.getEstabelecimentos(params).then(response => {
						this.waitingEstabelecimentos = false
						if (response.status == 200) {
							this.options.estabelecimentos = []
							if (response.data.length) this.options.estabelecimentos.push({ value: null, text: '- Selecione -' })
							response.data.forEach(estabelecimento => {
								this.options.estabelecimentos.push({
									value: estabelecimento.cd_estabelecimento,
									text: estabelecimento.nm_estabelecimento
								})
							})
						}
						this.getDatas()
					})
				}
			},
			getDatasEspecialista (val) {
				this.model.dt_agenda = null
				this.options.especialistas = []
				if( val ){
					this.selectedEspecialistaDropdown = val
				} else{
					this.selectedEspecialistaDropdown = false
				}
				this.getDatas()
			},
			getDatas () {
				this.options.datas = []
				this.options.horarios = []
				this.model.cd_horario_agendamento = null
				this.model.dt_agenda = null

				// @params: ie_tipo_agenda*, ie_telemedicina*, cd_estabelecimento*, dt_consulta, cd_especialista, cd_procedimento, cd_especialidade
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_estabelecimento']

				if (['C'].includes(this.model.ie_tipo_agenda)) fields.push('cd_especialidade')

				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_estabelecimento: this.model.cd_estabelecimento
				}

				if (this.model.cd_especialidade) params.cd_especialidade = this.model.cd_especialidade
				//if (this.model.cd_especialista) params.cd_especialista = this.model.cd_especialista
				if (this.model.cd_especialista_estabelecimento) params.cd_especialista = this.model.cd_especialista_estabelecimento
				if (this.model.cd_procedimento) params.cd_procedimento = this.model.cd_procedimento

				if (['E', 'P'].includes(this.model.ie_tipo_agenda)) {
					params.cd_procedimento = this.selectedEstabelecimento.id
				}

				this.waitingDatas = true
				Agendamento.getDatasCadastro(params).then(response => {
					this.waitingDatas = false
					if (response.status == 200) {
						this.options.datas = []
						response.data.forEach(data => {
							this.options.datas.push(moment(data.dt_agenda)._d)
						})
					}
					if (['E', 'P'].includes(this.model.ie_tipo_agenda)) this.getHorarios()
					// else if (['C'].includes(this.model.ie_tipo_agenda)) this.getEspecialistas()
				})
			},
			getEspecialistasEstabelecimento () {
				this.options.especialistasEstabelecimento = []
				this.model.cd_especialista = null

				// @params: ie_tipo_agenda*, ie_telemedicina*, cd_estabelecimento*, dt_consulta, cd_especialidade
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_especialidade', 'cd_estabelecimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_especialidade: this.model.cd_especialidade,
					cd_estabelecimento: this.model.cd_estabelecimento
				}

				if (this.model.dt_agenda){
					params.dt_consulta = moment(this.model.dt_agenda).format('YYYY-MM-DD')
					let dateChecked = false
					this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == params.dt_consulta) dateChecked = true })
					if (!dateChecked) { this.model.dt_agenda = null; return 0 }
				}

				this.waitingEspecialistasEstabelecimento = true
				Agendamento.getEspecialistasCadastro(params).then(response => {
					this.waitingEspecialistasEstabelecimento = false
					if (response.status == 200) {
						this.options.especialistasEstabelecimento = []
						response.data.forEach(especialista => {
							this.options.especialistasEstabelecimento.push({
								value: especialista.cd_especialista,
								text: especialista.nm_especialista,
								ds_observacao_agenda: especialista.ds_observacao_agenda,
								preco: especialista.preco,
								preco_f: this.formatPrice(especialista.preco)
							})
						})
					}
					this.getHorarios()
				})
			},
			getEspecialistas () {
				this.options.especialistas = []
				this.model.cd_especialista = null

				// @params: ie_tipo_agenda*, ie_telemedicina*, cd_estabelecimento*, dt_consulta, cd_especialidade
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_especialidade', 'cd_estabelecimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_especialidade: this.model.cd_especialidade,
					cd_estabelecimento: this.model.cd_estabelecimento
				}

				if ( this.selectedEspecialistaDropdown && this.model.cd_especialista_estabelecimento != null )
					params['cd_especialista'] = this.model.cd_especialista_estabelecimento

				if (this.model.dt_agenda){
					params.dt_consulta = moment(this.model.dt_agenda).format('YYYY-MM-DD')
					let dateChecked = false
					this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == params.dt_consulta) dateChecked = true })
					if (!dateChecked) { this.model.dt_agenda = null; return 0 }
				}

				this.waitingEspecialistas = true
				Agendamento.getEspecialistasCadastro(params).then(response => {
					this.waitingEspecialistas = false
					if (response.status == 200) {
						this.options.especialistas = []
						response.data.forEach(especialista => {
							this.options.especialistas.push({
								value: especialista.cd_especialista,
								text: especialista.nm_especialista,
								ds_observacao_agenda: especialista.ds_observacao_agenda,
								preco: especialista.preco,
								preco_f: this.formatPrice(especialista.preco)
							})
						})
					}
					this.getHorarios()
				})
			},
			onDayClick (ev) {
				let dateChecked = false
				this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == ev.id) dateChecked = true })
				if (dateChecked) this.model.dt_agenda = moment(ev.id)._d
			},
			getHorarios () {
				this.options.horarios = []
				this.model.cd_horario_agendamento = null

				// @params: ie_tipo_agenda*, ie_telemedicina*, cd_estabelecimento*, dt_consulta*,
				//          cd_especialista** ou cd_procedimento**,
				//          cd_especialidade
				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_estabelecimento', 'dt_agenda']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! this.model.cd_especialista && ! this.model.cd_procedimento) requiredChecked = false
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_estabelecimento: this.model.cd_estabelecimento,
					dt_consulta: moment(this.model.dt_agenda).format('YYYY-MM-DD')
				}

				if (this.model.cd_especialista) {
					params.cd_especialista = this.model.cd_especialista
					if (this.model.cd_especialidade) params.cd_especialidade = this.model.cd_especialidade
				} else {
					params.cd_procedimento = this.model.cd_procedimento
				}

				this.waitingHorarios = true
				Agendamento.getHorariosCadastro(params).then(response => {
					this.waitingHorarios = false
					if (response.status == 200) {
						this.options.horarios = {}
						response.data.forEach(horario => {
							if (this.options.horarios[horario.cd_agenda] == undefined)
								this.options.horarios[horario.cd_agenda] = {}

							if (this.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento] == undefined)
								this.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento] = []

							horario.ie_status = horario.status
							this.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento].push({
								value: horario.id,
								text: moment(horario.hr_agenda, 'HH:mm:ss', true).format('HH:mm'),
								disabled: horario.ie_status != 'L',
								status: horario.status,
								cd_dia_atendimento: horario.cd_dia_atendimento,
								hr_inicio: horario.hr_inicio,
								hr_fim: horario.hr_fim,
								cd_agenda: horario.cd_agenda,
								nm_especialista: horario.nm_especialista,
								ds_motivo_bloqueio: horario.cd_dia_bloqueio__ds_motivo_bloqueio
							})
						})

						for (let i in this.options.horarios) {
							for (let j in this.options.horarios[i]) {
								this.options.horarios[i][j].sort( (a, b) => {
									return Date.parse('01/01/2011 '+a.text+':00') > Date.parse('01/01/2011 '+b.text+':00')
								} )
							}
						}

					}
				})
			},
			criarHorario () {
				if (! moment(this.dialogCriarHorario_data.hr_agenda, 'HH:mm', true).isValid()) {
					this.$toast.error('Informe um horário válido', { duration: 3000 })
					return 0
				}
				this.$toast.success('Horário criado!', { duration: 3000 })
				this.dialogCriarHorario = false

				let novoHorario = {
					disabled: false,
					text: this.dialogCriarHorario_data.hr_agenda,
					value: this.dialogCriarHorario_data.hr_agenda,
					parent: this.dialogCriarHorario_base
				}

				this.options.horarios[this.dialogCriarHorario_base.cd_agenda][this.dialogCriarHorario_base.cd_dia_atendimento].push(novoHorario)
				this.model.cd_horario_agendamento = this.dialogCriarHorario_data.hr_agenda
				this.horarioSelecionado = novoHorario


			},
			todosHorariosIndisponiveis () {
				let check = true
				this.options.horarios.forEach(horario => { if (! horario.disabled) check = false })
				return check
			},
			getOrigens() {
				let params = { ie_status: true }
				this.waitingOrigem = true
				Agendamento.origemAtendimentoFindAll(params).then(response => {
					this.waitingOrigem = false
					if (response.status === 200) {
						this.options.origens = response.data
					}
				})
			},
			criarOrigem() {
				if (! this.dialogCriarOrigem_data.ds_origem) {
					this.$toast.error("Campo origem é obrigatório.")
					return
				}

				this.waitingOrigem = true
				Agendamento.origemAtendimentoSave(this.dialogCriarOrigem_data).then(response => {
					this.waitingOrigem = false
					if ([200, 201, 204].includes(response.status)) {
						this.$toast.success("Cadastro salvo com sucesso.")
						this.dialogCriarOrigem = false
						this.getOrigens()

					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			},
			incrementarQuantidade(horario, i){

				if (!['E', 'P'].includes(horario.info.ie_tipo_agenda))
					return false;

				if( i === '+1' ){
					horario.info.nr_quantidade = horario.info.nr_quantidade+1
				} else if( i === '-1' ){
					horario.info.nr_quantidade = horario.info.nr_quantidade-1
				} else {
					horario.info.nr_quantidade = i
				}

				if( horario.info.nr_quantidade <=0 )
					horario.info.nr_quantidade = 1

				let _returnSuccess = (responseData) => {
					/*
					let agendamento = {
						id: responseData.id,
						paciente: this.$refs.buscarPaciente.model,
						info: Object.assign({}, this.model),
						dataSend
					}
					*/

					horario.info.nr_quantidade = responseData.nr_quantidade
					horario.info.preco_f = this.$root.formatPrice(responseData.nr_valor*responseData.nr_quantidade)
					horario.info.preco = responseData.nr_valor*responseData.nr_quantidade

					this.$toast.success('Horário Atualizado!', { duration: 3000 })
				}

				let dataSend = Object.assign({}, horario.info)
				dataSend = {
					cd_horario_agendamento:horario.id,
					cd_atendimento: this.cliente.atendimento.id,
					ie_reservar:'R',
					nr_quantidade: horario.info.nr_quantidade,
					cd_pessoa_fisica: horario.info.cd_paciente.id,
					cd_procedimento: horario.info.cd_procedimento_estabelecimento
				}

				this.waitingQuantidade = Object.assign({}, this.waitingQuantidade, {
					[horario.id] : true
				})

				this.waitingForm = true
				Agendamento.reservarHorario(dataSend).then(response => {

					this.waitingQuantidade = Object.assign({}, this.waitingQuantidade, {
						[response.data.id] : false
					})

					this.waitingForm = false
					if (response.status == 200) _returnSuccess(response.data)
					else if (response.status == 400) {
						/*
						if (response.data.cd_horario_agendamento) {
							if (typeof response.data.cd_horario_agendamento == 'string')
								this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
							else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						}
						*/
						if (response.data.non_field_errors)
							response.data.non_field_errors.forEach(errorMsg => {
								this.$toast.error(errorMsg, { duration: 3000 })
							})
						else Object.values(response.data).forEach(errorMsg => {
							if (typeof errorMsg == 'object')
								this.$toast.error(errorMsg[0], { duration: 3000 })
							else this.$toast.error(errorMsg, { duration: 3000 })
						})
					}
				})
			}
		}
	}
</script>
