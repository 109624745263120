<template lang="pug">
    Panel(v-if='agendamento' header="Agendamento")
        .p-grid.p-fluid.box-imprimir-agendamento(style='margin: 0 auto; max-width: 500px;')
            .p-col-12
                fieldset
                    legend.text-title Paciente
                    p <b>Nome: </b> {{ agendamento.paciente.nm_pessoa_fisica }}
                    p <b>CPF: </b> {{ agendamento.paciente.nr_cpf_f }}
                    p <b>Sexo: </b> {{ agendamento.paciente.ie_sexo_f }}
                    p <b>Data de nascimento: </b> {{ agendamento.paciente.dt_nascimento_f }}
                    p <b>Município residencial: </b> {{ agendamento.paciente.cd_cidade.nm_cidade }} - {{ agendamento.paciente.cd_cidade.cd_estado.ds_sigla }}
            .p-col-12
                fieldset
                    legend.text-title Informações do agendamento
                    p <b>Estabelecimento: </b> {{ agendamento.info.nm_estabelecimento }}
                    p <b>Tipo de atendimento: </b> {{ agendamento.info.ie_tipo_agenda_f }} {{ agendamento.info.ie_tipo_atendimento == 2 ? 'por vídeo': 'presencial' }}
                    div(v-if='agendamento.info.cd_procedimento')
                        p <b>Procedimento: </b> {{ agendamento.info.ds_procedimento }}
                    div(v-else)
                        p <b>Especialidade: </b> {{ agendamento.info.nm_especialidade }}
                        p <b>Especialista: </b> {{ agendamento.info.nm_especialista }}
                    p <b>Data: </b> {{ agendamento.info.dt_agenda_f }}
                    p <b>Horário: </b> {{ agendamento.info.hr_agenda_f }}
                    p <b>Preço: </b> {{ agendamento.info.preco_f }}
            .p-col-12
                p.msg-success.ta-center
                    span <i class='pi pi-thumbs-up'></i> Agendamento reservado!
                    a.link-agendarOutro(
                        @click='$parent.model.ie_tipo_atendimento = null; $parent.agendamento = null') &nbsp;Agendar outro.
</template>

<style lang="scss">
    .box-imprimir-agendamento {
        border: 1px solid #c8c8c8;
        padding: 20px;
        fieldset {
            border: 1px solid #c8c8c8;
        }
        p {
            margin: 6px 0;
            color: #333;
        }
        .msg-success {
            font-weight: 700;
            color: #689f38;
            margin-bottom: -5px;
        }
        .text-title {
            color: #657786;
            font-size: 18px;
            font-weight: 700;
        }
        .link-agendarOutro {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import Button from 'primevue/button'

    export default {
        watch: {
            '$parent.agendamento': function(agendamento) {
                if (agendamento) this.agendamento = agendamento
            }
        },
        components: { Panel, Button },
        data () {
            return {
                agendamento: null
            }
        }
    }
</script>